import React, { useState } from "react";
import { PrimaryFeatures } from "./components/new/PrimaryFeatures";
import { Hero } from "./components/new/Hero";
import { Header } from "./components/new/Header";
import { SecondaryFeatures } from "./components/new/SecondaryFeatures";
import { Pricing } from "./components/new/Pricing";
import { Faqs } from "./components/new/Faqs";
import { Footer } from "./components/new/Footer";
import { createRoot } from "react-dom/client";
import "./styles/globals.css";
import { ContactFormDialog } from "./components/new/ContactDialog";

export default function Home() {
  const [isContactFormOpen, setIsContactFormOpen] = useState(false);

  const openContactForm = () => setIsContactFormOpen(true);
  const closeContactForm = () => setIsContactFormOpen(false);

  return (
    <>
      <Header openContactForm={openContactForm} />
      <main>
        <Hero />
        <PrimaryFeatures />
        <SecondaryFeatures />
        <Pricing />
        <Faqs />
      </main>
      <Footer openContactForm={openContactForm} />
      <ContactFormDialog isOpen={isContactFormOpen} onClose={closeContactForm} />
    </>
  );
}

const rootElement = document.getElementById("root");
if (rootElement) {
  const root = createRoot(rootElement);
  root.render(<Home />);
} else {
  console.error("Failed to find the root element in the index.");
}
