import React from "react";
import { Tab, TabGroup, TabList, TabPanel, TabPanels } from "@headlessui/react";
import clsx from "clsx";

import { Container } from "@/components/new/Container";
import screenshotContacts from "../../../assets/images/screenshots/contacts.png";
import screenshotInventory from "../../../assets/images/screenshots/inventory.png";
import screenshotProfitLoss from "../../../assets/images/screenshots/profit-loss.png";
import { ArrowTrendingUpIcon, ChartPieIcon, ForwardIcon } from "@heroicons/react/16/solid";

interface FeatureItem {
  name: React.ReactNode;
  summary: string;
  description: string;
  image: string;
  icon: React.ComponentType<React.SVGProps<SVGSVGElement>>;
}

const features: Array<FeatureItem> = [
  {
    name: "Dashboard",
    summary: "A single source of truth for the most important data in your company.",
    description:
      "Sync KPIs from Excel to Notion, enabling lightning-fast, company-wide access to critical data without needing to open a spreadsheet.",
    image: screenshotProfitLoss,
    icon: ChartPieIcon,
  },
  {
    name: "Product Analytics",
    summary: "Integrate A/B testing results and feature impact data for rapid product iteration.",
    description:
      "Automate the flow of experiment outcomes and feature metrics. Enable your whole team to make data-driven decisions no matter which tools they work from.",
    image: screenshotInventory,
    icon: ArrowTrendingUpIcon,
  },
  {
    name: "Automation",
    summary: "Achieve complex automations in minutes, not weeks of Zapier steps.",
    description:
      "If you're tired of complex automation tools, so are we. SyncExcel is the simplest way to get your data moving without 30+ steps in competing platforms.",
    image: screenshotContacts,
    icon: ForwardIcon,
  },
];

function Feature({
  feature,
  isActive,
  className,
  ...props
}: React.ComponentPropsWithoutRef<"div"> & {
  feature: FeatureItem;
  isActive: boolean;
}) {
  return (
    <div className={clsx(className, !isActive && "opacity-75 hover:opacity-100")} {...props}>
      <div
        className={clsx(
          "w-9 h-9 rounded-lg flex items-center justify-center",
          isActive ? "bg-green-600" : "bg-zinc-400"
        )}
      >
        <feature.icon className="h-7 w-7 text-white" aria-hidden="true" />
      </div>
      <h3 className={clsx("mt-6 text-sm font-medium font-sans", isActive ? "text-green-600" : "text-zinc-400")}>
        {feature.name}
      </h3>
      <p className="mt-2 font-sans text-xl text-zinc-100">{feature.summary}</p>
      <p className="mt-4 text-sm font-sans text-zinc-400">{feature.description}</p>
    </div>
  );
}

function FeaturesMobile() {
  return (
    <div className="-mx-4 mt-20 flex flex-col gap-y-10 overflow-hidden px-4 sm:-mx-6 sm:px-6 lg:hidden">
      {features.map((feature) => (
        <div key={feature.summary}>
          <Feature feature={feature} className="mx-auto max-w-2xl" isActive />
          <div className="relative mt-10 pb-10">
            <div className="absolute -inset-x-4 bottom-0 top-8 bg-zinc-800 sm:-inset-x-6" />
            <div className="relative mx-auto w-[52.75rem] overflow-hidden rounded-xl bg-white shadow-lg shadow-zinc-100/20 ring-1 ring-zinc-100/10">
              <img className="w-full" src={feature.image} alt="" sizes="52.75rem" />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

function FeaturesDesktop() {
  return (
    <TabGroup className="hidden lg:mt-20 lg:block">
      {({ selectedIndex }) => (
        <>
          <TabList className="grid grid-cols-3 gap-x-8">
            {features.map((feature, featureIndex) => (
              <Feature
                key={feature.summary}
                feature={{
                  ...feature,
                  name: (
                    <Tab className="ui-not-focus-visible:outline-none">
                      <span className="absolute inset-0" />
                      {feature.name}
                    </Tab>
                  ),
                }}
                isActive={featureIndex === selectedIndex}
                className="relative"
              />
            ))}
          </TabList>
          <TabPanels className="relative mt-20 overflow-hidden rounded-4xl bg-zinc-800 px-14 py-16 xl:px-16">
            <div className="-mx-5 flex">
              {features.map((feature, featureIndex) => (
                <TabPanel
                  static
                  key={feature.summary}
                  className={clsx(
                    "px-5 transition duration-500 ease-in-out ui-not-focus-visible:outline-none",
                    featureIndex !== selectedIndex && "opacity-60"
                  )}
                  style={{ transform: `translateX(-${selectedIndex * 100}%)` }}
                  aria-hidden={featureIndex !== selectedIndex}
                >
                  <div className="w-[52.75rem] overflow-hidden rounded-xl bg-white shadow-lg shadow-zinc-100/20">
                    <img className="w-full" src={feature.image} alt="" sizes="52.75rem" />
                  </div>
                </TabPanel>
              ))}
            </div>
            <div className="pointer-events-none absolute inset-0 rounded-4xl ring-1 ring-inset ring-zinc-100/10" />
          </TabPanels>
        </>
      )}
    </TabGroup>
  );
}

export function SecondaryFeatures() {
  return (
    <section
      id="secondary-features"
      aria-label="Features for simplifying everyday business tasks"
      className="pb-14 pt-20 sm:pb-20 sm:pt-32 lg:pb-32 bg-zinc-950"
    >
      <Container>
        <div className="mx-auto max-w-2xl md:text-center">
          <h2 className="font-bold text-3xl text-transparent bg-clip-text bg-gradient-to-br from-white to-white/50 sm:text-4xl md:text-5xl">
            Automation, made{" "}
            <span className="text-transparent bg-clip-text bg-gradient-to-br from-green-300 to-green-700">simple</span>
          </h2>
          <p className="font-sans mt-4 text-lg tracking-tight text-zinc-400">
            SyncExcel not only makes the following use cases possible, but also makes them{" "}
            <span className="italic">simple </span>
            without writing a line of code.
          </p>
        </div>
        <FeaturesMobile />
        <FeaturesDesktop />
      </Container>
    </section>
  );
}
