import React, { useState } from "react";
import { Dialog, DialogPanel } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { Button } from "./Button";

const navigation = [
  { name: "Features", href: "#" },
  { name: "Pricing", href: "#" },
  { name: "FAQ", href: "#" },
  { name: "Contact", href: "#" },
];

export function Header({ openContactForm }: { openContactForm: () => void }) {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  return (
    <header className="bg-zinc-950">
      <nav aria-label="Global" className="mx-auto flex max-w-7xl items-center justify-between p-6 md:px-8">
        <div className="flex md:flex-1">
          <a href="#" className="-m-1.5 p-1.5">
            <span className="sr-only">SyncExcel</span>
            <span className="text-lg font-black text-white">SyncExcel</span>
          </a>
        </div>
        <div className="flex md:hidden">
          <Button variant="ghost" onClick={() => setMobileMenuOpen(true)} className="rounded-md !p-1">
            <span className="sr-only">Open main menu</span>
            <Bars3Icon aria-hidden="true" className="h-6 w-6" />
          </Button>
        </div>
        <div className="hidden md:flex md:gap-x-12">
          {navigation.map((item) => (
            <a
              key={item.name}
              href={item.href}
              onClick={
                item.name === "Contact"
                  ? (e) => {
                      e.preventDefault();
                      openContactForm();
                    }
                  : undefined
              }
              className="text-sm font-semibold leading-6 text-zinc-400 hover:text-zinc-100"
            >
              {item.name}
            </a>
          ))}
        </div>
        <div className="hidden md:flex md:flex-1 md:justify-end">
          <Button variant="solid" href="#">
            Sign up
          </Button>
        </div>
      </nav>
      <Dialog open={mobileMenuOpen} onClose={setMobileMenuOpen} className="md:hidden">
        <div className="fixed inset-0 z-10" />
        <DialogPanel className="fixed inset-y-0 w-screen z-10 overflow-y-auto bg-zinc-950 px-6 py-6 sm:ring-1 sm:ring-white/10">
          <div className="flex items-center justify-between">
            <a href="#" className="-m-1.5 p-1.5">
              <span className="sr-only">SyncExcel</span>
              <span className="text-lg font-black text-white">SyncExcel</span>
            </a>
            <Button variant="ghost" onClick={() => setMobileMenuOpen(false)} className="rounded-md !p-1">
              <span className="sr-only">Close menu</span>
              <XMarkIcon aria-hidden="true" className="h-6 w-6" />
            </Button>
          </div>
          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-gray-500/25">
              <div className="py-6">
                <Button variant="solid" href="#" className="!block rounded-md text-center">
                  Sign up
                </Button>
              </div>
              <div className="space-y-2 py-6">
                {navigation.map((item) => (
                  <a
                    key={item.name}
                    href={item.href}
                    onClick={
                      item.name === "Contact"
                        ? (e) => {
                            e.preventDefault();
                            openContactForm();
                          }
                        : undefined
                    }
                    className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-zinc-400 hover:text-zinc-100"
                  >
                    {item.name}
                  </a>
                ))}
              </div>
            </div>
          </div>
        </DialogPanel>
      </Dialog>
    </header>
  );
}
