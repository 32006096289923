import React, { useState } from "react";
import { Radio, RadioGroup } from "@headlessui/react";
import { CheckIcon } from "@heroicons/react/20/solid";

type PricePeriod = "monthly" | "annually";

type Frequency = {
  value: PricePeriod;
  label: string;
  priceSuffix: string;
};

type Tier = {
  name: string;
  id: string;
  href: string;
  price: { monthly: string; annually: string };
  description: string;
  features: string[];
  mostPopular: boolean;
};

const frequencies: Frequency[] = [
  { value: "monthly", label: "Monthly", priceSuffix: "/month" },
  { value: "annually", label: "Annually", priceSuffix: "/month" },
];

const tiers: Tier[] = [
  {
    name: "Business",
    id: "tier-business",
    href: "/register",
    price: { monthly: "$99", annually: "$79" },
    description: "Keep your team in sync with live data from Notion and Excel.",
    features: [
      "10,000 Synced Rows",
      "Unlimited database/table connections",
      "Unlimited columns",
      "Unlimited changes",
      "5 minute updates",
      "Granular sync control",
      "Excel formula sync",
      "Two-way page creation/deletion",
    ],
    mostPopular: true,
  },
  {
    name: "Enterprise",
    id: "tier-enterprise",
    href: "/register",
    price: { monthly: "Contact Us", annually: "Contact Us" },
    description: "Everything you need to scale your data operations.",
    features: [
      "Everything in Business and...",
      "Unlimited Synced Rows",
      "Priority support with dedicated Slack channel",
    ],
    mostPopular: false,
  },
];

function classNames(...classes: (string | boolean | undefined)[]) {
  return classes.filter(Boolean).join(" ");
}

export function Pricing() {
  const [frequency, setFrequency] = useState<Frequency>(frequencies[0]);

  return (
    <div className="bg-zinc-950 py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-4xl text-center">
          <h2 className="font-bold text-3xl text-transparent bg-clip-text bg-gradient-to-br from-white to-white/50 sm:text-4xl md:text-5xl">
            Pricing built for your business
          </h2>
        </div>
        <p className="mx-auto mt-6 max-w-2xl text-center text-lg leading-8 text-zinc-400">
          All our plans come with a 14-day free trial. No credit card required.
        </p>
        <div className="mt-16 flex justify-center">
          <fieldset aria-label="Payment frequency">
            <RadioGroup
              value={frequency}
              onChange={setFrequency}
              className="grid grid-cols-2 gap-x-1 rounded-full bg-white/5 p-1 text-center text-xs font-semibold leading-5 text-white"
            >
              {frequencies.map((option) => (
                <Radio
                  key={option.value}
                  value={option}
                  className="cursor-pointer rounded-full px-2.5 py-1 data-[checked]:bg-green-600"
                >
                  {option.label}
                </Radio>
              ))}
            </RadioGroup>
          </fieldset>
        </div>
        <div className="isolate mx-auto mt-10 grid max-w-md grid-cols-1 gap-8 lg:max-w-5xl lg:grid-cols-2">
          {tiers.map((tier) => (
            <div
              key={tier.id}
              className={classNames(
                tier.mostPopular ? "bg-white/5 ring-2 ring-green-600" : "ring-1 ring-white/10",
                "rounded-3xl p-8 xl:p-10"
              )}
            >
              <div className="flex items-center justify-between gap-x-4">
                <h3 id={tier.id} className="text-lg font-semibold leading-8 text-zinc-100">
                  {tier.name}
                </h3>
                {tier.mostPopular ? (
                  <p className="rounded-full bg-green-600 px-2.5 py-1 text-xs font-semibold leading-5 text-white">
                    Most popular
                  </p>
                ) : null}
              </div>
              <p className="mt-4 text-sm leading-6 text-zinc-400">{tier.description}</p>
              <p className="mt-6 flex items-baseline gap-x-1">
                <span className="text-4xl font-bold tracking-tight text-zinc-100">{tier.price[frequency.value]}</span>
                {tier.price[frequency.value] !== "Contact Us" && (
                  <span className="text-sm font-semibold leading-6 text-zinc-400">{frequency.priceSuffix}</span>
                )}
              </p>
              <a
                href={tier.href}
                aria-describedby={tier.id}
                className={classNames(
                  tier.mostPopular
                    ? "bg-green-600 text-white shadow-sm hover:bg-green-500 focus-visible:outline-green-600"
                    : "bg-zinc-800 text-zinc-100 hover:bg-white hover:text-black focus-visible:outline-white",
                  "mt-6 block rounded-md px-3 py-2 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
                )}
              >
                Start free trial
              </a>
              <ul role="list" className="mt-8 space-y-3 text-sm leading-6 text-zinc-300 xl:mt-10">
                {tier.features.map((feature) => (
                  <li key={feature} className="flex gap-x-3">
                    <CheckIcon aria-hidden="true" className="h-6 w-5 flex-none text-white" />
                    {feature}
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
