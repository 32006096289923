import React from "react";
import clsx from "clsx";
import { Link } from "@/components/new/Link";

const baseStyles = {
  solid:
    "group inline-flex items-center justify-center rounded-full py-2 px-4 text-sm font-sans font-bold focus:outline-none focus-visible:outline-2 focus-visible:outline-offset-2 bg-white text-black hover:bg-green-600 hover:text-white active:bg-green-700 active:text-white focus-visible:outline-white transition-colors duration-200",
  ghost:
    "group inline-flex items-center justify-center rounded-full py-2 px-4 text-sm font-sans font-bold focus:outline-none text-zinc-400 hover:bg-zinc-800 hover:text-zinc-100 active:bg-zinc-900 active:text-zinc-200 focus-visible:outline-zinc-700 transition-colors duration-200",
};

type ButtonProps = {
  variant?: "solid" | "ghost";
} & (
  | Omit<React.ComponentPropsWithoutRef<typeof Link>, "color">
  | (Omit<React.ComponentPropsWithoutRef<"button">, "color"> & {
      href?: undefined;
    })
);

export function Button({ className, variant = "solid", ...props }: ButtonProps) {
  className = clsx(baseStyles[variant], className);

  return typeof props.href === "undefined" ? (
    <button className={className} {...props} />
  ) : (
    <Link className={className} {...props} />
  );
}
