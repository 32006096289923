import React, { useEffect, useState } from "react";
import { Tab, TabGroup, TabList, TabPanel, TabPanels } from "@headlessui/react";
import clsx from "clsx";

import { Container } from "@/components/new/Container";
import screenshotExpenses from "../../../assets/images/screenshots/expenses.png";
import screenshotPayroll from "../../../assets/images/screenshots/payroll.png";
import screenshotReporting from "../../../assets/images/screenshots/reporting.png";
import screenshotVatReturns from "../../../assets/images/screenshots/vat-returns.png";

const features = [
  {
    title: "Central hub",
    description: "Manage all your Excel spreadsheets and Notion workspaces from a single Excel add-in.",
    image: screenshotPayroll,
  },
  {
    title: "Granular sync",
    description:
      "Choose the source of truth at the column level and sync data in the direction you want. Support for every Notion property type, so your data stays intact.",
    image: screenshotVatReturns,
  },
  {
    title: "Formulas",
    description: "Work with real-time data and formulas in Excel and see the results in Notion. It's that simple.",
    image: screenshotExpenses,
  },
  {
    title: "Page sync",
    description: "Add or remove rows in Notion and Excel and the other side stays in sync.",
    image: screenshotReporting,
  },
];

export function PrimaryFeatures() {
  let [tabOrientation, setTabOrientation] = useState<"horizontal" | "vertical">("horizontal");

  useEffect(() => {
    let lgMediaQuery = window.matchMedia("(min-width: 1024px)");

    function onMediaQueryChange({ matches }: { matches: boolean }) {
      setTabOrientation(matches ? "vertical" : "horizontal");
    }

    onMediaQueryChange(lgMediaQuery);
    lgMediaQuery.addEventListener("change", onMediaQueryChange);

    return () => {
      lgMediaQuery.removeEventListener("change", onMediaQueryChange);
    };
  }, []);

  return (
    <section
      id="features"
      aria-label="Features for running your books"
      className="relative overflow-hidden bg-zinc-950 pb-28 pt-20 sm:py-32"
    >
      <Container className="relative">
        <div className="max-w-3xl md:mx-auto md:text-center">
          <h2 className="font-bold text-3xl text-transparent bg-clip-text bg-gradient-to-br from-white to-white/50 sm:text-4xl md:text-5xl">
            Sync in{" "}
            <span className="text-transparent bg-clip-text bg-gradient-to-br from-green-300 to-green-700">minutes</span>
          </h2>
          <p className="mt-6 text-lg tracking-tight text-zinc-400">
            A simple, yet powerful integration to sync as much data as you can throw at us. Set up your first sync in
            minutes and never deal with data silos and manual data-entry again.
          </p>
        </div>
        <TabGroup
          className="mt-16 grid grid-cols-1 items-center gap-y-2 pt-10 sm:gap-y-6 md:mt-20 lg:grid-cols-12 lg:pt-0 lg:overflow-visible"
          vertical={tabOrientation === "vertical"}
        >
          {({ selectedIndex }) => (
            <>
              <div className="-mx-4 flex overflow-x-auto pb-4 sm:mx-0 sm:overflow-visible sm:pb-0 lg:col-span-5">
                <TabList className="relative z-10 flex gap-x-4 whitespace-nowrap px-4 sm:mx-auto sm:px-0 lg:mx-0 lg:block lg:gap-x-0 lg:gap-y-1 lg:whitespace-normal">
                  {features.map((feature, featureIndex) => (
                    <div
                      key={feature.title}
                      className={clsx(
                        "group relative rounded-full px-4 py-1 lg:rounded-l-xl lg:rounded-r-none lg:p-6",
                        selectedIndex === featureIndex
                          ? "bg-zinc-800 lg:ring-1 lg:ring-inset lg:ring-zinc-400/10"
                          : "hover:bg-zinc-800"
                      )}
                    >
                      <h3>
                        <Tab
                          className={clsx(
                            "font-semibold text-lg ui-not-focus-visible:outline-none",
                            selectedIndex === featureIndex ? "text-zinc-100" : "text-zinc-400 hover:text-zinc-100"
                          )}
                        >
                          <span className="absolute inset-0 rounded-full lg:rounded-l-xl lg:rounded-r-none" />
                          {feature.title}
                        </Tab>
                      </h3>
                      <p
                        className={clsx(
                          "mt-2 hidden text-base lg:block font-sans",
                          selectedIndex === featureIndex ? "text-zinc-200" : "text-zinc-500 group-hover:text-zinc-400"
                        )}
                      >
                        {feature.description}
                      </p>
                    </div>
                  ))}
                </TabList>
              </div>
              <TabPanels className="lg:col-span-7">
                {features.map((feature) => (
                  <TabPanel key={feature.title} unmount={false}>
                    <div className="relative sm:px-6 lg:hidden">
                      <div className="absolute -inset-x-4 bottom-[-4.25rem] top-[-6.5rem] ring-1 ring-inset ring-zinc-800 sm:inset-x-0 sm:rounded-t-xl" />
                      <p className="relative font-sans mx-auto max-w-2xl text-base text-zinc-400 sm:text-center">
                        {feature.description}
                      </p>
                    </div>
                    <div className="mt-10 w-[45rem] overflow-hidden rounded-xl shadow-xl shadow-zinc-100/20 sm:w-auto lg:mt-0 lg:w-[67.8125rem]">
                      <img
                        className="w-full"
                        src={feature.image}
                        alt=""
                        sizes="(min-width: 1024px) 67.8125rem, (min-width: 640px) 100vw, 45rem"
                      />
                    </div>
                  </TabPanel>
                ))}
              </TabPanels>
            </>
          )}
        </TabGroup>
      </Container>
    </section>
  );
}
